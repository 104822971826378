import {BASE_IMAGES} from "../../main/constants/BASE_IMAGES";

export const FetchedProductsData = [
    {
        id: 1,
        type: 1,
        code: "H-221",
        price: "36900",
        imagePath: BASE_IMAGES.weddingCategory
    },
    {
        id: 2,
        type: 2,
        code: "H-232",
        price: "36900",
        imagePath: BASE_IMAGES.birthdayCategory
    },
    {
        id: 3,
        type: 5,
        code: "H-212",
        price: "36900",
        imagePath: BASE_IMAGES.baptismCategory
    },
    {
        type: 3,
        code: "H-219",
        price: "36900",
        imagePath: BASE_IMAGES.firstToothCategory
    },
    {
        type: 1,
        code: "H-218",
        price: "36900",
        imagePath: BASE_IMAGES.engagementCategory
    },
    {
        type: 4,
        code: "H-222",
        price: "36900",
        imagePath: BASE_IMAGES.baptismCategory
    },
    {
        type: 2,
        code: "H-221",
        price: "36900",
        imagePath: BASE_IMAGES.birthdayCategory
    },
    {
        type: 3,
        code: "H-225",
        price: "36900",
        imagePath: BASE_IMAGES.weddingCategory
    },
    {
        type: 5,
        code: "H-234",
        price: "36900",
        imagePath: BASE_IMAGES.firstToothCategory
    },
    {
        type: 1,
        code: "H-211",
        price: "36900",
        imagePath: BASE_IMAGES.engagementCategory
    },
    {
        type: 1,
        code: "H-228",
        price: "36900",
        imagePath: BASE_IMAGES.birthdayCategory
    },
    {
        type: 1,
        code: "H-220",
        price: "36900",
        imagePath: BASE_IMAGES.weddingCategory
    },
    {
        type: 1,
        code: "H-231",
        price: "36900",
        imagePath: BASE_IMAGES.birthdayImage
    },
    {
        type: 1,
        code: "H-232",
        price: "36900",
        imagePath: BASE_IMAGES.baptismCategory
    },
    {
        type: 1,
        code: "H-212",
        price: "36900",
        imagePath: BASE_IMAGES.engagementCategory
    },
    {
        type: 1,
        code: "H-219",
        price: "36900",
        imagePath: BASE_IMAGES.weddingCategory
    },
    // {
    //     type: 1,
    //     code: "H-218",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1722927851730.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-222",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1722927868055.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-221",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721897590366.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-225",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721054094884.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-234",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721054345318.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-211",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721053927364.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-228",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721053395339.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-220",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1719923100099.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-231",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1719812380201.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-232",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723704299742.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-212",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723704330860.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-219",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723202681324.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-218",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1722927851730.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-222",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1722927868055.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-221",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721897590366.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-225",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721054094884.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-234",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721054345318.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-211",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721053927364.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-228",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721053395339.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-220",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1719923100099.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-231",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1719812380201.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-232",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723704299742.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-212",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723704330860.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-219",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723202681324.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-218",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1722927851730.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-222",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1722927868055.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-221",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721897590366.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-225",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721054094884.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-234",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721054345318.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-211",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721053927364.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-228",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721053395339.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-220",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1719923100099.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-231",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1719812380201.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-232",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723704299742.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-212",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723704330860.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-219",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723202681324.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-218",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1722927851730.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-222",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1722927868055.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-221",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721897590366.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-225",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721054094884.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-234",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721054345318.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-211",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721053927364.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-228",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721053395339.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-220",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1719923100099.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-231",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1719812380201.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-232",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723704299742.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-212",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723704330860.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-219",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723202681324.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-218",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1722927851730.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-222",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1722927868055.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-221",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721897590366.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-225",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721054094884.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-234",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721054345318.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-211",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721053927364.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-228",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721053395339.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-220",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1719923100099.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-231",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1719812380201.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-232",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723704299742.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-212",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723704330860.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-219",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1723202681324.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-218",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1722927851730.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-222",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1722927868055.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-221",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721897590366.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-225",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721054094884.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-234",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721054345318.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-211",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721053927364.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-228",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1721053395339.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-220",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1719923100099.jpg"
    // },
    // {
    //     type: 1,
    //     code: "H-231",
    //     imagePath: "https://api.aldoro.am/api/images/product-compress/1719812380201.jpg"
    // }
];