import {Carousel} from "react-responsive-carousel";
import {BASE_IMAGES} from "../../../../main/constants/BASE_IMAGES";
import {v4 as uuid} from "uuid";
import {useTranslation} from "react-i18next";

function Banner() {

    const { t } = useTranslation()
    const images = [BASE_IMAGES.weddingImage, BASE_IMAGES.birthdayImage, BASE_IMAGES.weddingImage]

    return (
        <Carousel showArrows={false}
                  showIndicators={true}
                  showStatus={false}
                  showThumbs={false}
                  autoPlay={true}
                  infiniteLoop={true}
                  emulateTouch={true}
                  interval={5000}>
            {images.map(image => (
                <div key={uuid()} style={{position: "relative"}}>
                    <h2 className="slider-content">{t("wedding")}</h2>
                    <img className={"image-slider-carousel-image"}
                         src={image}
                         alt={""}/>
                </div>
            ))}
        </Carousel>
    )
}

export default Banner