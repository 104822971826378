import { motion } from "framer-motion";
import { useEffect } from "react";
import './style/invitation-2.css';
import MainSection from "./sections/MainSection";
import VenueSections from "./sections/venue-sections/VenueSections";
import ProgramOfDay from "./sections/program-of-day/ProgramOfDay";
import {INVITATION_IMAGES} from "./helper/Helper";

function Invitation2() {

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }, 100);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <motion.section
                className="invitation-2"
                initial={{ opacity: 0, filter: "blur(10px)" }}
                animate={{ opacity: 1, filter: "blur(0)" }}
                transition={{ duration: 1.5 }}
            >
                <MainSection date="December 14, 2024"/>
                <ProgramOfDay/>
                <VenueSections/>
                <p className="invitation-2-end-title">Սիրով կսպասենք Ձեզ</p>
            </motion.section>
            <style jsx="true">{`
                #main-section {
                    background-image: url("${INVITATION_IMAGES.mainImage}");
                }
            `}</style>
        </>
    );
}

export default Invitation2;
