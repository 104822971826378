
export const BestSellersData = [
    {
        id: 1,
        type: 1
    },
    {
        id: 2,
        type: 2
    },
    {
        id: 3,
        type: 3
    },
    {
        id: 4,
        type: 4
    },
    {
        id: 5,
        type: 5
    }
];