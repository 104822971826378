import Header from "../../header/Header";
import AboutUs from "../home/about-us-section/AboutUs";
import Footer from "../../footer/Footer";
import {getGlobalStyle} from "../../../main/utility/utility";

function AboutUsPage() {

    return (
        <>
            <Header/>
            <AboutUs backgroundColor={getGlobalStyle("--white")} margin={"100px auto"}/>
            <Footer/>
        </>
    )
}

export default AboutUsPage