import {INVITATION_IMAGES} from "../../helper/Helper";

function ProgramOfDayDesktop() {
    return (
        <div className={"program-of-day"}>
            <div className={"program-of-day-container"}>
                <p className={"program-of-day-time"}>13:45</p>

                <div className={"program-icon-container"}>
                    <img src={INVITATION_IMAGES.ring} className={"program-icon"}/>
                </div>

                <div className={"program-of-day-place-container"}>
                    <p className={"program-of-day-place"}>Պսակադրություն</p>
                    <p className={"program-of-day-place-name"}>Սուրբ Խաչ Եկեղեցի</p>
                </div>
            </div>

            <div className={"program-of-day-line"}/>

            <div className={"program-of-day-container"}>
                <p className={"program-of-day-time"}>13:45</p>

                <div className={"program-icon-container"}>
                    <img src={INVITATION_IMAGES.glass} className={"program-icon"}/>
                </div>

                <div className={"program-of-day-place-container"}>
                    <p className={"program-of-day-place"}>Հարսանեկան խնջույք</p>
                    <p className={"program-of-day-place-name"}>«Աղաբաբյանս» ռեստորանային համալիր</p>
                </div>
            </div>
        </div>
    )
}

export default ProgramOfDayDesktop