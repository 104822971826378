import Header from "../../header/Header";
import AboutUs from "./about-us-section/AboutUs";
import Services from "./services-section/Services";
import Footer from "../../footer/Footer";
import Banner from "./banner-section/Banner";
import HowToOrder from "./how-to-order-section/HowToOrder";
import BestSellers from "./best-sellers-section/BestSellers";
import FloatingButtons from "../../custom components/floating-buttons/FloatingButtons";

function HomePage() {
    return (
            <div className={"app-container"}>
                <Header/>
                <Banner/>
                <AboutUs/>
                <Services/>
                <HowToOrder/>
                <BestSellers/>
                <Footer/>

                <FloatingButtons/>
            </div>
    )
}

export default HomePage