import {MotionDiv} from "../../../../custom components/MotionDiv";
import {INVITATION_IMAGES} from "../helper/Helper";

function GuestsInvitationSection({ id, eventDay, eventMonth, eventYear }) {

    return (
        <section id={id}>
            <div className={"guest-invitation-section"}>
                <div className={"guest-invitation-section-description"}>

                    <div className={"guest-invitation-section-content"}>
                        <p className={"guest-invitation-section-title"}>FRIENDS AND FAMILY</p>
                        <p className={"guest-invitation-section-message"}>
                            You are warmly invited to participate in the celebration of the most important day of our life, our union in marriage.
                        </p>
                        <p className={"guest-invitation-section-date"}>{eventDay}․{eventMonth}․{eventYear}</p>
                    </div>

                </div>

                <MotionDiv id={id} duration={3} className="guest-invitation-section-images" content={
                    <>
                        <div className="guest-invitation-section-top-image">
                            <img src={INVITATION_IMAGES.spouseImage1} className="guest-invitation-section-image" />
                        </div>
                        <div className="guest-invitation-section-bottom-image">
                            <img src={INVITATION_IMAGES.spouseImage2} className="guest-invitation-section-image" />
                        </div>
                    </>
                }/>
            </div>
        </section>
    )
}

export default GuestsInvitationSection