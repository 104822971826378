import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";
import './style/header.css';
import {useScreenSize} from "../../main/providers/ScreenSizeProvider";
import {SCREEN_SIZES} from "../../main/constants/SCREEN_SIZES";

function Header() {

    const { isSmallerThan } = useScreenSize();
    const location = useLocation()

    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [lastScrollY]);

    useEffect(() => {
        scrollToTop()
    }, [location]);

    function scrollHandler() {
        const currentScrollY = window.scrollY;

        if (currentScrollY > lastScrollY && currentScrollY > 30) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }

        setLastScrollY(currentScrollY);
    };

    function scrollToTop() {
        window.scrollTo({top: 0, behavior: "smooth"})
    }

    return (
        <>
            {isSmallerThan(SCREEN_SIZES.TABLET_M) ? (
                <HeaderMobile isVisible={isVisible}/>
            ) : (
                <HeaderDesktop isVisible={isVisible}/>
            )}
        </>
    )

}

export default Header