import {useTranslation} from "react-i18next";
import FooterPaymentSection from "./sections/FooterPaymentSection";
import {Link} from "react-router-dom";
import {getRouterPrefix} from "../router/getRouterPrefix";
import {BASE_ROUTERS} from "../../main/constants/BASE_ROUTERS";
import {BASE_IMAGES} from "../../main/constants/BASE_IMAGES";
import FooterContactsSection from "./sections/FooterContactsSection";

function FooterMobile() {

    const { t} = useTranslation()

    return (
        <>
            <div className="footer-container">

                <Link className="footer-logo" to={getRouterPrefix() + BASE_ROUTERS.main}>
                    <img src={BASE_IMAGES.logoIcon} className={"footer-logo-icon"}/>
                </Link>

                <FooterPaymentSection/>

                <FooterContactsSection/>

            </div>

            <div className={"footer-copyright"}>
                <p>{t("copyright-info")}</p>
            </div>
        </>
    )
}

export default FooterMobile