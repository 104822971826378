import {useLocation, useNavigate, useRoutes} from "react-router-dom";
import { useEffect, useState } from "react";
import {GetCurrentCountry} from "../../api/GetCurrentCountry";
import {BASE_ROUTERS} from "../../main/constants/BASE_ROUTERS";
import Language from "./Language";
import HomePage from "../pages/home/HomePage";
import AboutUsPage from "../pages/about-us/AboutUsPage";
import ServicesPage from "../pages/services/ServicesPage";
import HowToOrderPage from "../pages/how-to-order/HowToOrderPage";
import CatalogPage from "../pages/catalog/CatalogPage";
import Invitation2 from "../pages/invitations/invitation-2/Invitation2";
import InvitationDashboard from "../pages/invitations/InvitationDashboard";

function Router({ footerHeight, token, setToken }) {

    const location = useLocation()
    const navigate = useNavigate();
    const getCurrentCountryRequest = new GetCurrentCountry()
    const [languagePrefix, setLanguagePrefix] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCurrentCountryRequest.sendRequest()
                const countryCode = data.country

                let prefix
                switch (countryCode) {
                    case 'RU':
                    case 'KZ':
                    case 'BY':
                    case 'UA':
                        prefix = 'ru'
                        break
                    case 'AM':
                        prefix = null
                        break
                    default:
                        prefix = 'en'
                }

                setLanguagePrefix(prefix)
            } catch (error) {
                console.error(error)
            }
        }

        const _ = fetchData()
    }, [])

    useEffect(() => {
        if (languagePrefix && !location.pathname.startsWith(`/${languagePrefix}`)) {
            const currentPathWithoutLang = location.pathname.replace(/^\/(en|ru)/, "");
            navigate(`/${languagePrefix}${currentPathWithoutLang}`);
        }

    }, [languagePrefix, location.pathname]);

    const languageRoutes = [languagePrefix, "en", "ru", ""].map(language => ({
        element: <Language/>,
        children: getRoutes(language)
    }))

    const routes = languageRoutes.reduce((allRoutes, languageRoute) => {
        allRoutes.push(languageRoute)
        let routes = allRoutes.concat(languageRoute.children)

        return routes
    }, [])

    function getRouteElements(prefix) {
        return [
            { path: `*`, element: <div>Not Found</div> },
            { path: `${prefix}${BASE_ROUTERS.main}`, element: <HomePage/>},
            { path: `${prefix}${BASE_ROUTERS.aboutUs}`, element: <AboutUsPage/>},
            { path: `${prefix}${BASE_ROUTERS.services}`, element: <ServicesPage/>},
            { path: `${prefix}${BASE_ROUTERS.howToOrder}`, element: <HowToOrderPage/>},
            { path: `${prefix}/:categoryType`, element: <CatalogPage/>},
            { path: `${prefix}/:categoryType/:id`, element: <InvitationDashboard/>}
        ]
    }

    function getRoutes(language) {
        const languagePrefix = `/${language}`

        return getRouteElements(languagePrefix)
    }

    return useRoutes(routes)
}

export default Router
