import { BASE_IMAGES } from "../../../main/constants/BASE_IMAGES";
import './styles/floating-buttons.css';
import { useState } from "react";

function FloatingButtons() {

    const { accountUnregisteredIcon, checkoutIcon, favIcon, xIcon } = BASE_IMAGES;

    const [isOpened, setIsOpened] = useState(false);

    function toggleActionsHandler() {
        setIsOpened(prevState => !prevState);
    }

    const renderIconBox = (icon, iconClass, boxClass) => (
        <div className={`icon-box icon-clear-box ${isOpened ? boxClass : `${boxClass}-close`}`}>
            <img src={icon} className={iconClass}/>
        </div>
    );

    return (
        <section className="floating-buttons">
            <div className={`floating-content ${isOpened ? 'opened' : ''}`}>
                { renderIconBox(accountUnregisteredIcon, "account-icon", "slide-3") }
                { renderIconBox(checkoutIcon, "checkout-icon", "slide-2") }
                { renderIconBox(favIcon, "favorites-icon", "slide-1") }
            </div>

            <div className="icon-box" onClick={toggleActionsHandler}>
                <img src={isOpened ? xIcon : accountUnregisteredIcon} className={isOpened ? "x-icon" : "opening-icon"}/>
            </div>
        </section>
    );
}

export default FloatingButtons;
