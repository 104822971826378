import ProgramOfDaySection from "./sections/ProgramOfDaySection";
import ConfirmAttendanceSection from "./sections/ConfirmAttendanceSection";
import CountdownSection from "./sections/CountdownSection";
import GuestsInvitationSection from "./sections/GuestsInvitationSection";
import './style/invitation-1.css';
import {motion} from "framer-motion";
import {useEffect} from "react";
import {DateFormat, formatEventMonth, formatEventYear, SECTIONS} from "./helper/Helper";

function Invitation1() {

    const eventDay = 14;
    const eventMonth = 'December';
    const eventYear = 2024;

    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({ top: 0, behavior: "smooth" });
        };

        const timer = setTimeout(scrollToTop, 100);
        return () => clearTimeout(timer);
    }, []);

    function scrollToSection() {
        const section = document.getElementById(SECTIONS.guestInvitationSection);
        section.scrollIntoView({ behavior: "smooth" });
    }

    return (
        <motion.section
            className="invitation-1"
            initial={{ opacity: 0, filter: "blur(10px)" }}
            animate={{ opacity: 1, filter: "blur(0)" }}
            transition={{ duration: 1.5 }}
        >
            <CountdownSection id={SECTIONS.countdownSection}
                              scrollToSection={scrollToSection}
                              eventDay={eventDay}
                              eventMonth={eventMonth}
                              eventYear={eventYear}/>
            <GuestsInvitationSection
                id={SECTIONS.guestInvitationSection}
                eventDay={eventDay}
                eventMonth={formatEventMonth(eventMonth, DateFormat.NUMERIC)}
                eventYear={formatEventYear(eventYear, DateFormat.NUMERIC)}
            />
            <ProgramOfDaySection id={SECTIONS.programOfDaySection}/>
            <ConfirmAttendanceSection id={SECTIONS.confirmAttendanceSection}/>
        </motion.section>
    )
}

export default Invitation1