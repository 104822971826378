import {useTranslation} from "react-i18next";
import '../styles/catalog-header.css';
import SortingDropdown from "../../../custom components/dropdown/SortingDropdown";
import {useScreenSize} from "../../../../main/providers/ScreenSizeProvider";
import {pxToNumber, getGlobalStyle} from "../../../../main/utility/utility";
import {SCREEN_SIZES} from "../../../../main/constants/SCREEN_SIZES";
import {BASE_IMAGES} from "../../../../main/constants/BASE_IMAGES";
import {useEffect, useState} from "react";

function CatalogHeader({ itemsCount }) {

    const {t} = useTranslation();
    const { isSmallerThan } = useScreenSize();
    const safeDocument = typeof document !== 'undefined' ? document : {}
    const { body } = safeDocument
    const items = [  "price-high-to-low", "price-low-to-high"];

    const [sidebar, setSidebar] = useState(false)

    useEffect(() => {
        body.style.overflow = sidebar ? 'hidden' : "scroll"
    }, [sidebar])

    function selectFilterAction(item) { }

    function openFiltersBarAction() {
        setSidebar(!sidebar)
    }

    return (
        <div className={"catalog-header"}>
            <h2 className={"catalog-title"}>{t("web-invitations")}</h2>

            <div className={"catalog-content"}>
                <p className={"catalog-results"}>
                    {t("{{itemsCount}} results", { itemsCount: itemsCount })}
                </p>
                {
                    isSmallerThan(SCREEN_SIZES.TABLET_M) ? (
                        <div className={"catalog-filter-button-container"} onClick={openFiltersBarAction}>
                            <img src={BASE_IMAGES.filterIcon} className="filter-icon" alt="Filter icon"/>
                            <p className="catalog-filter-title">{t("filters")}</p>
                        </div>
                    ) : (
                        <SortingDropdown
                            items={items}
                            defaultSortingType={items[0]}
                            onItemSelect={selectFilterAction}
                            shouldShowSortingType={!isSmallerThan(SCREEN_SIZES.TABLET_M)}
                        />
                    )
                }
            </div>
        </div>
    );
}

export default CatalogHeader;