import {BASE_IMAGES} from "./BASE_IMAGES";

export const SERVICE_TYPES = {
    WEDDING: {
        value: 1,
        title: "wedding",
        image: BASE_IMAGES.weddingCategory
    },
    BIRTHDAY: {
        value: 2,
        title: "birthday",
        image: BASE_IMAGES.birthdayCategory
    },
    ENGAGEMENT: {
        value: 3,
        title: "engagement",
        image: BASE_IMAGES.engagementCategory
    },
    BAPTISM: {
        value: 4,
        title: "baptism",
        image: BASE_IMAGES.baptismCategory
    },
    FIRST_TOOTH: {
        value: 5,
        title: "first-tooth",
        image: BASE_IMAGES.firstToothCategory
    },

    getItemByTitle: (title) => {
        return SERVICE_TYPES.allCases().find(el => title === el.title)
    },
    getItemByValue: (value) => {
        return SERVICE_TYPES.allCases().find(el => value === el.value)
    },
    allCases: () => {
        return Object.keys(SERVICE_TYPES).filter(el => {
            return el !== "getItemByTitle" && el !== "getItemByValue" && el !== "allCases"

        }).map(key => SERVICE_TYPES[key])
    },
}