import {v4 as uuid} from "uuid";
import {useTranslation} from "react-i18next";
import {BASE_IMAGES} from "../../../main/constants/BASE_IMAGES";
import FooterLanguagesSection from "./FooterLanguagesSection";

function FooterPaymentSection() {

    const { t } = useTranslation()
    const paymentIcons = [
        BASE_IMAGES.mastercardIcon, BASE_IMAGES.visaIcon,
        BASE_IMAGES.amexIcon
    ]

    return (
        <div className="footer-payment-section">

            <div className="footer-payment-section-container">
                <div>
                    <h2 className={"footer-section-title"}>{t("payment-methods")}</h2>
                    <div className={"footer-payment-icons"}>{paymentIcons.map((icon => {
                        return <img key={uuid()} src={icon} className={"footer-payment-icon"}/>
                    }))}</div>
                </div>

                <FooterLanguagesSection/>
            </div>

        </div>
    )
}

export default FooterPaymentSection