import React from "react";
import {motion} from "framer-motion";
import {BrowserRouter} from "react-router-dom";
import Router from "../router/router";
import {ScreenSizeProvider} from "../../main/providers/ScreenSizeProvider";

function App() {

    return (
        <ScreenSizeProvider>
            <motion.section initial={{opacity: 0, y: -20}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: 20}}
                            transition={{duration: 1}}
                            className={"app"}>
                <BrowserRouter>
                    <Router/>
                </BrowserRouter>
            </motion.section>
        </ScreenSizeProvider>
    );
}

export default App;
