import {SECTION} from "../../../main/constants/SECTION";
import {BASE_IMAGES} from "../../../main/constants/BASE_IMAGES";
import {BASE_CONSTANTS} from "../../../main/constants/BASE_CONSTANTS";
import {v4 as uuid} from "uuid";
import {useTranslation} from "react-i18next";

function FooterContactsSection() {

    const { t } = useTranslation()
    const socialIcons = [
        BASE_IMAGES.facebookIcon, BASE_IMAGES.instagramIcon,
        BASE_IMAGES.whatsappIcon, BASE_IMAGES.tiktokIcon,
        BASE_IMAGES.youtubeIcon, BASE_IMAGES.pinterestIcon
    ]

    return (
        <div className="footer-contacts-section" id={SECTION.contactUs.id}>

            <div className="footer-contacts-section-container">
                <div>
                    <h2 className={"footer-section-title"}>{t("contact-us")}</h2>

                    <div className={"footer-contact-infos-container"}>
                        <div className={"footer-contact"}>
                            <img src={BASE_IMAGES.phoneIcon} className={"footer-contact-icon"}/>
                            <h2 className={"footer-contact-info"}>{BASE_CONSTANTS.phoneNumber}</h2>
                        </div>
                        <div className={"footer-contact"}>
                            <img src={BASE_IMAGES.emailIcon} className={"footer-contact-icon"}/>
                            <h2 className={"footer-contact-info"}>{BASE_CONSTANTS.email}</h2>
                        </div>
                    </div>
                </div>

                <div>
                    <h2 className={"footer-section-title"}>{t("follow-us")}</h2>
                    <div className={"footer-social-icons"}>{socialIcons.map((icon => {
                        return <img key={uuid()} src={icon} className={"footer-social-icon"}/>
                    }))}</div>
                </div>
            </div>

        </div>
    )
}

export default FooterContactsSection