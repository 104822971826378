import React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { scrollToSection } from "../../main/utility/utility";
import { getRouterPrefix } from "../router/getRouterPrefix";
import { BASE_ROUTERS } from "../../main/constants/BASE_ROUTERS";
import { BASE_IMAGES } from "../../main/constants/BASE_IMAGES";
import { useTranslation } from "react-i18next";
import { SECTION } from "../../main/constants/SECTION";

function HeaderDesktop({ isVisible }) {
    const { t } = useTranslation();
    const location = useLocation();

    function handleSectionClick(e, sectionId) {
        if (location.pathname === BASE_ROUTERS.main) {
            e.preventDefault(); // Prevent NavLink's default navigation
            scrollToSection(sectionId);
        }
    }

    return (
        <>
            <header className={`header ${isVisible ? 'header-visible' : 'header-hidden'}`}>
                <div className="header-section">
                    <hr className="header-line" />

                    <NavLink
                        to={BASE_ROUTERS.aboutUs}
                        className="header-category"
                        onClick={(e) => handleSectionClick(e, SECTION.aboutUs.id)}
                    >
                        {t(SECTION.aboutUs.title)}
                    </NavLink>

                    <NavLink
                        to={BASE_ROUTERS.services}
                        className="header-category"
                        onClick={(e) => handleSectionClick(e, SECTION.services.id)}
                    >
                        {t(SECTION.services.title)}
                    </NavLink>
                </div>

                <NavLink className="header-logo" to={getRouterPrefix() + BASE_ROUTERS.main}>
                    <img src={BASE_IMAGES.logoIcon} className="header-logo-icon" alt="Logo" />
                </NavLink>

                <div className="header-section">
                    <NavLink
                        to={BASE_ROUTERS.howToOrder}
                        className="header-category"
                        onClick={(e) => handleSectionClick(e, SECTION.howToOrder.id)}
                    >
                        {t(SECTION.howToOrder.title)}
                    </NavLink>

                    <span className={"header-category"}
                          onClick={() => scrollToSection(SECTION.contactUs.id)}
                    >
                        {t(SECTION.contactUs.title)}
                    </span>

                    <hr className="header-line" />
                </div>
            </header>
            <div className="header-height" />
        </>
    );
}

export default HeaderDesktop;
