import {NavLink} from "react-router-dom";
import {INVITATION_IMAGES} from "../helper/Helper";

function ProgramOfDaySection({ id }) {
    return (
        <section id="program-of-day-section">
            <h1 className="program-of-day-title">PROGRAM OF THE DAY</h1>

            <ProgramSection
                iconSrc={INVITATION_IMAGES.invitationWeddingCeremony}
                name="Wedding Ceremony"
                time="14:30"
                place="Saint Gevorg Church"
                address="Ararat Region, Marmarashen village, 36th Street"
                iconClass="wedding-ceremony-icon"
                navLink={"https://yandex.com/maps/org/235695177733/?ll=44.468128%2C40.053192&z=18.17"}
            />

            <ProgramSection
                iconSrc={INVITATION_IMAGES.invitationWeddingReception}
                name="Wedding Reception"
                time="17:00"
                place="Morena Restaurant Complex"
                address="Masis, Mkhitar Heratsi Street, 7/8"
                iconClass="wedding-reception-icon"
                navLink={"https://yandex.com/maps/org/222913353582/?ll=44.444778%2C40.059849&z=17"}
            />
        </section>
    );
}

function ProgramSection(
    {
        iconSrc,
        name,
        time,
        place,
        address,
        iconClass,
        navLink
    }
) {

    return (
        <div className="program-section-part">
            <div className="program-section-content">
                <img src={iconSrc} className={`program-icon ${iconClass}`} alt={`${name} icon`} />
                <p className="program-section-name">{name}</p>
                <p className="program-section-time">{time}</p>
                <p className="program-section-place">{place}</p>
                <p className="program-section-address">{address}</p>
            </div>
            <NavLink to={navLink} className="dark-button">How to get there</NavLink>
        </div>
    );
}

export default ProgramOfDaySection