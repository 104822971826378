import CountdownTimer from "../../../../custom components/countdown_timer/CountdownTimer";
import {BASE_IMAGES} from "../../../../../main/constants/BASE_IMAGES";

function CountdownSection({ id, scrollToSection = () => {}, eventDay, eventMonth, eventYear }) {

    const eventDate = new Date(
        `${eventMonth} ${eventDay}, ${eventYear} 23:59:59`
    ).getTime()

    return (
        <section id={id}>

            <div className={"countdown-section-content"}>
                <h1 className={"countdown-section-title"}>HRANT & KRISTINE</h1>
                <h2 className={"countdown-section-date"}>{eventMonth} {eventDay}, {eventYear}</h2>
                <p className={"countdown-section-time-countdown"}>Wedding day countdown!</p>

                <CountdownTimer eventDate={eventDate}/>

                <button onClick={scrollToSection}>
                    <img src={BASE_IMAGES.rightArrowIcon} className={"countdown-section-arrow"}/>
                </button>
            </div>

        </section>
    )
}

export default CountdownSection