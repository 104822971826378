import React, {useEffect, useRef, useState} from "react";
import {BASE_IMAGES} from "../../main/constants/BASE_IMAGES";
import {motion} from "framer-motion";
import {SidebarData} from "./models/SidebarData";
import {v4 as uuid} from "uuid";
import {useTranslation} from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler/esm/OutsideClickHandler";
import {isMatchingRoute, scrollToSection} from "../../main/utility/utility";
import {getRouterPrefix} from "../router/getRouterPrefix";
import {BASE_ROUTERS} from "../../main/constants/BASE_ROUTERS";
import {Link, useLocation} from "react-router-dom";
import {SECTION} from "../../main/constants/SECTION";

function useOutsideHandler(ref, action = () => {}) {

    function handleClickOutside(event) {
        action()
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)

        return () => { document.removeEventListener("mousedown", handleClickOutside) }
    }, [ref])
}

function HeaderMobile({ isVisible }) {

    const safeDocument = typeof document !== 'undefined' ? document : {};
    const { body } = safeDocument;
    const {t} = useTranslation()
    const ref = useRef()
    const isMainRoute = isMatchingRoute(BASE_ROUTERS.main)

    const [sidebar, setSidebar] = useState(false);

    useOutsideHandler(ref)

    useEffect(() => {
        body.style.overflow = sidebar ? 'hidden' : 'scroll';
    }, [sidebar]);

    function showSidebar() {
        setSidebar(!sidebar);
    }

    function handler(key) {
        setSidebar(false);
        scrollToSection(key)
    }

    return (
        <>
            <div className={`header-top-bar ${isVisible ? 'header-visible' : 'header-hidden'}`}>
                <Link className="header-logo" to={getRouterPrefix() + BASE_ROUTERS.main}>
                    <img className={"header-top-bar-logo-icon"} src={BASE_IMAGES.logoIcon} alt="" title="Nav Icon"/>
                </Link>

                <div className={"header-top-bar-burger-icon-container"} onClick={showSidebar}>
                    <img className={"header-top-bar-burger-icon"} src={BASE_IMAGES.burgerIcon} alt="" title="Nav Icon"/>
                </div>
            </div>

            <div className={"header-sidebar"}>
                <motion.div
                    className={"header-sidebar-overlay"}
                    initial={{opacity: 0}}
                    animate={{opacity: sidebar ? 1 : 0}}
                    transition={{duration: 0.4, ease: 'easeInOut'}}
                    onClick={showSidebar}
                />

                <motion.div
                    ref={ref}
                    className={"header-sidebar-content"}
                    initial={{x: '100%'}}
                    animate={{x: sidebar ? '0%' : '100%'}}
                    transition={{duration: 0.4, ease: 'easeInOut'}}
                >
                    <OutsideClickHandler onOutsideClick={() => {
                        setSidebar(false)
                    }}>
                        <div className={"header-sidebar-sidebar-menu"}>
                            <div className={"header-sidebar-x-icon-container"} onClick={showSidebar}>
                                <img src={BASE_IMAGES.xIcon} className={"header-close-icon"} alt="" title="X Icon"/>
                            </div>

                            <div className={"header-menu-items"}>
                                {SidebarData.map((item) => (
                                    isMainRoute || item.title === SECTION.contactUs.title ? (
                                        <h2 key={uuid()}
                                            className={"header-menu-item"}
                                            onClick={() => { handler(item.key) }}>
                                            {t(item.title)}
                                        </h2>
                                    ) : (
                                        <Link to={`${getRouterPrefix()}/${item.urlName}`}
                                              key={uuid()}
                                              className={"header-menu-item"}>
                                            {t(item.title)}
                                        </Link>
                                    )
                                ))}
                            </div>
                        </div>
                    </OutsideClickHandler>
                </motion.div>
                <div className={"header-sidebar-height"}/>
            </div>

            <style>{`
                .header-sidebar-overlay {
                    pointer-events: ${sidebar ? 'auto' : 'none'};
                }
            `}</style>
        </>
    );
}

export default HeaderMobile