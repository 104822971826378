export const BASE_IMAGES = {
    // logo
    logoIcon: '/assets/logo/hyoor_logo.svg',
    // banner
    weddingImage: '/assets/banner/wedding_image.png',
    birthdayImage: '/assets/banner/birthday_image.png',
    // contact-icons, social-icons, payment-icons
    facebookIcon: '/assets/social-icons/facebook.svg',
    instagramIcon: '/assets/social-icons/instagram.svg',
    whatsappIcon: '/assets/social-icons/whatsapp.svg',
    tiktokIcon: '/assets/social-icons/tiktok.svg',
    youtubeIcon: '/assets/social-icons/youtube.svg',
    pinterestIcon: '/assets/social-icons/pinterest.svg',
    emailIcon: '/assets/contact-icons/email.svg',
    phoneIcon: '/assets/contact-icons/phone.svg',
    amexIcon: '/assets/payment-icons/amex.svg',
    arcaIcon: '/assets/payment-icons/arca.svg',
    easyPayIcon: '/assets/payment-icons/easypay.svg',
    idramIcon: '/assets/payment-icons/idram.svg',
    mastercardIcon: '/assets/payment-icons/mastercard.svg',
    telcellIcon: '/assets/payment-icons/telcell.svg',
    visaIcon: '/assets/payment-icons/visa.svg',
    // categories
    weddingCategory: '/assets/categories/wedding_category.jpg',
    engagementCategory: '/assets/categories/engagement_category.jpg',
    birthdayCategory: '/assets/categories/birthday_category.jpg',
    baptismCategory: '/assets/categories/baptism_category.jpg',
    firstToothCategory: '/assets/categories/first_tooth_category.jpg',
    // how to order
    howToOrderCallToOperator: '/assets/how-to-order/how_to_order_call_to_operator.png',
    howToOrderChoosing: '/assets/how-to-order/how_to_order_choosing.png',
    howToOrderMakeYourself: '/assets/how-to-order/how_to_order_make_yourself.png',
    howToOrderProcessing: '/assets/how-to-order/how_to_order_processing.png',
    howToOrderResult: '/assets/how-to-order/how_to_order_result.png',

    rightArrowIcon: '/assets/icons/right_arrow_icon.svg',
    leftArrowIcon: '/assets/left-arrow.svg',
    downArrowIcon: '/assets/icons/down_arrow_icon.svg',
    sortingIcon: '/assets/icons/sorting_icon.svg',
    filterIcon: '/assets/icons/filter_icon.svg',

    xIcon: '/assets/icons/close_icon.svg',
    burgerIcon: '/assets/icons/burger_icon.svg',
    accountRegisteredIcon: '/assets/icons/account_unregistered_icon.svg',
    accountUnregisteredIcon: '/assets/icons/account_registered_icon.svg',
    checkoutIcon: '/assets/icons/checkout_icon.svg',
    favIcon: '/assets/icons/fav_icon.svg',
}