import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Services from "../home/services-section/Services";

function ServicesPage() {

    return (
        <div>
            <Header/>
            <Services margin={"100px 0"}/>
            <Footer/>
        </div>
    )
}

export default ServicesPage