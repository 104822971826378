import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import './styles/dropdown.css';
import {useState} from "react";
import {BASE_IMAGES} from "../../../main/constants/BASE_IMAGES";

function SortingDropdown(
    {
        items, defaultSortingType, onItemSelect = (item) => {}, shouldShowSortingType = true
    }
) {

    const { t } = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [sortingType, setSortingType] = useState(defaultSortingType);

    function handleItemSelect(item) {
        setSortingType(item)
        onItemSelect(item)
        setIsDropdownOpen(false);
    };

    return (
        <div className="dropdown-wrapper">
            <OutsideClickHandler onOutsideClick={() => setIsDropdownOpen(false)}>
                <div className="dropdown" onClick={() => { setIsDropdownOpen(true) }}>
                    <img src={BASE_IMAGES.sortingIcon} className="sort-icon" alt="Sort icon"/>
                    <p className="title">{t("sort-by")}{shouldShowSortingType && t("colon")}</p>

                    {shouldShowSortingType && (
                        <>
                            <p className="title">{t(sortingType)}</p>
                            <img src={BASE_IMAGES.downArrowIcon} className="arrow-icon" alt="SortingDropdown arrow"/>
                        </>
                    )}

                    {isDropdownOpen && (
                        <div className="dropdown-content">
                        {items.map((item) => (
                                <div key={item}
                                     className="dropdown-item"
                                     onClick={(e) => {
                                         e.stopPropagation();
                                         handleItemSelect(item);
                                     }}>
                                    {t(item)}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </OutsideClickHandler>
        </div>
    );
}

export default SortingDropdown;