
export const FetchedCategoriesData = [
    {
        type: 1
    },
    {
        type: 2
    },
    {
        type: 3
    },
    {
        type: 4
    },
    {
        type: 5
    }
];