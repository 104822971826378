import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {MotionDiv} from "../../../custom components/MotionDiv";
import React from "react";
import {useTranslation} from "react-i18next";
import {BASE_IMAGES} from "../../../../main/constants/BASE_IMAGES";
import {SECTION} from "../../../../main/constants/SECTION";
import {getGlobalStyle} from "../../../../main/utility/utility";

function HowToOrder({ backgroundColor, margin  }) {

    const {t} = useTranslation()
    const defaultBackgroundColor = getGlobalStyle("--tertiary-color")
    const defaultMargin = `0 auto ${getGlobalStyle("--padding-content")}`

    return (
        <>
            <div id={SECTION.howToOrder.id} className={"how-to-order"}>
                <h2 className="how-to-order-title">{t("how-to-order")}</h2>

                <MotionDiv id={SECTION.howToOrder.id}
                           className={"how-to-order-content"}
                           duration={2}
                           content={
                               <>
                                   <div>
                                       <h2 className={"how-to-order-choosing-option"}>{t("option {{number}}", {number: "1"})}</h2>
                                       <div className={"how-to-order-section"}>
                                           <div>
                                               <img src={BASE_IMAGES.howToOrderChoosing}
                                                    className={"how-to-order-image"}/>
                                               <h2>{t("how-to-order-choosing")}</h2>
                                           </div>
                                           <div>
                                               <img src={BASE_IMAGES.howToOrderMakeYourself}
                                                    className={"how-to-order-image"}/>
                                               <h2>{t("how-to-order-make-yourself")}</h2>
                                           </div>
                                           <div>
                                               <img src={BASE_IMAGES.howToOrderResult}
                                                    className={"how-to-order-image"}/>
                                               <h2>{t("how-to-order-result-1")}</h2>
                                           </div>
                                       </div>
                                   </div>
                                   <div>
                                       <h2 className={"how-to-order-choosing-option"}>{t("option {{number}}", {number: "2"})}</h2>
                                       <div className={"how-to-order-section"}>
                                           <div>
                                               <img src={BASE_IMAGES.howToOrderChoosing}
                                                    className={"how-to-order-image"}/>
                                               <h2>{t("how-to-order-choosing")}</h2>
                                           </div>
                                           <div>
                                               <img src={BASE_IMAGES.howToOrderCallToOperator}
                                                    className={"how-to-order-image"}/>
                                               <h2>{t("how-to-order-call-to-operator")}</h2>
                                           </div>
                                           <div>
                                               <img src={BASE_IMAGES.howToOrderProcessing}
                                                    className={"how-to-order-image"}/>
                                               <h2>{t("how-to-order-processing")}</h2>
                                           </div>
                                           <div>
                                               <img src={BASE_IMAGES.howToOrderResult}
                                                    className={"how-to-order-image"}/>
                                               <h2>{t("how-to-order-result-2")}</h2>
                                           </div>
                                       </div>
                                   </div>
                               </>
                           }/>
            </div>
            <style>{`
            .how-to-order {
                background-color: ${backgroundColor || defaultBackgroundColor};
                margin: ${margin || defaultMargin} !important;
            }
            `}</style>
        </>
    )
}

export default HowToOrder