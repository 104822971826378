import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import './style/footer.css';
import FooterDesktop from "./FooterDesktop";
import {SCREEN_SIZES} from "../../main/constants/SCREEN_SIZES";
import {useScreenSize} from "../../main/providers/ScreenSizeProvider";
import FooterMobile from "./FooterMobile";
import FloatingButtons from "../custom components/floating-buttons/FloatingButtons";

function Footer() {

    const { isSmallerThan } = useScreenSize();
    const location = useLocation()

    useEffect(() => {
        scrollToTop()
    }, [location]);

    function scrollToTop() {
        window.scrollTo({top: 0, behavior: "smooth"})
    }

    return (
        <>
            <footer className={"footer"}>
                {isSmallerThan(SCREEN_SIZES.TABLET_M) ? (
                    <FooterMobile/>
                ) : (
                    <FooterDesktop/>
                )}

                <FloatingButtons/>
            </footer>
            <div className={"footer-height"}/>
        </>
    )

}

export default Footer