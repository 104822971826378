function MapWithPin({ latitude, longitude }) {
    const zoomLevel = 17;
    const pinStyle = "pm2rdl";
    const mapUrl = `https://yandex.com/map-widget/v1/?ll=${longitude},${latitude}&z=${zoomLevel}&pt=${longitude},${latitude},${pinStyle}`;

    return (
        <iframe
            src={mapUrl}
            className="venue-location-map"
            allowFullScreen
            loading="lazy"
            title="Venue location"
        ></iframe>
    );
}

export default MapWithPin