export const SECTIONS = {
    mainSection: "main-section",
    guestInvitationSection: "guest-invitation-section",
    programOfDaySection: "program-of-day-section",
    confirmAttendanceSection :"confirm-attendance-section",
}

export const INVITATION_IMAGES = {
    mainImage: "/assets/invitations/invitation-2/invitation_2_main_image.jpg",
    glass: '/assets/invitations/invitation-2/glass.svg',
    ring: '/assets/invitations/invitation-2/ring.svg',
    churchImage: '/assets/invitations/invitation-2/invitation_2_church_image.jpg',
    venueImage: '/assets/invitations/invitation-2/invitation_2_venue_image.jpg'
};
