import {Link} from "react-router-dom";
import {v4 as uuid} from "uuid";
import {SERVICE_TYPES} from "../../../../main/constants/SERVICE_TYPES";
import {useTranslation} from "react-i18next";
import '../styles/catalog-listing.css'

function CatalogListing({ invitationCards }) {

    const {t} = useTranslation()

    return (
        <div className={"catalog-listing"}>
            {invitationCards.map(item => (
                <Link to={`${item.id}`} key={uuid()} className={"catalog-item"}>
                    <img src={item.imagePath} className={"catalog-item-image"}/>
                    <div className={"catalog-item"}>
                        <p className={"catalog-item-code"}>{item.code}</p>
                        <p className={"catalog-item-type"}>{t(SERVICE_TYPES.getItemByValue(item.type).title)}</p>
                        <p className={"catalog-item-price"}>{item.price} ֏</p>
                    </div>
                </Link>
            ))}
        </div>
    )
}

export default CatalogListing