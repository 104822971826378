import {motion} from "framer-motion";
import React, {useEffect, useState} from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {SERVICE_TYPES} from "../../../../main/constants/SERVICE_TYPES";
import {FetchedCategoriesData} from "../../../../api/jsons/FetchedCategoriesData";
import {v4 as uuid} from "uuid";
import {getRouterPrefix} from "../../../router/getRouterPrefix";
import {MotionDiv} from "../../../custom components/MotionDiv";
import {pxToNumber, getGlobalStyle} from "../../../../main/utility/utility";
import {SECTION} from "../../../../main/constants/SECTION";
import CustomSlider, {ResponsiveSettings} from "../../../custom components/CustomSlider";

export default function Services({ margin }) {

    const { t } = useTranslation();
    const tabletMSize = getGlobalStyle("--tabletM");
    const desktopMSize = getGlobalStyle("--desktopM");
    const sliderContainerPadding = getGlobalStyle("--slider-container-padding")
    const slideImageSize = getGlobalStyle("--slide-image-square-size")
    const sliderItemsGap = getGlobalStyle("--slider-items-gap")

    const [sliderPaddingSize, setSliderPaddingSize] = useState(0)

    useEffect(() => {
        const paddings = pxToNumber(sliderContainerPadding) * 2
        const imageSize = pxToNumber(slideImageSize)
        const sliderItemsGapSize = pxToNumber(sliderItemsGap)

        setSliderPaddingSize((window.innerWidth - paddings - imageSize) / 2 - sliderItemsGapSize)
    }, []);

    return (
        <>
            <div id={SECTION.services.id} className={"services"}>
                <h2 className="services-title">{t("services")}</h2>
                <MotionDiv id={SECTION.services.id}
                           className={"services-slider-container"}
                           duration={2}
                           content={
                               <CustomSlider itemsCount={FetchedCategoriesData.length}
                                             slidesToShowCount={4}
                                             responsive={[
                                                 new ResponsiveSettings(
                                                     Number(tabletMSize),
                                                     1,
                                                     true,
                                                     `${sliderPaddingSize}px`
                                                 ),
                                                 new ResponsiveSettings(
                                                     Number(desktopMSize),
                                                     3
                                                 )
                                             ]}
                                             content={FetchedCategoriesData.map(item => {
                                                 const category = SERVICE_TYPES.getItemByValue(item.type);

                                                 return (
                                                     <motion.div key={uuid()}
                                                                 className={"category-container"}
                                                                 whileHover={{
                                                                     scale: 0.95,
                                                                     transition: {duration: 0.3}
                                                                 }}>
                                                         <Link to={getRouterPrefix() + `/${category.title}`}>
                                                             <img src={category.image} alt={category.title}/>
                                                             <h2 className={"category-name"}>{t(category.title)}</h2>
                                                         </Link>
                                                     </motion.div>
                                                 );
                                             })}
                               />
                           }>
                </MotionDiv>
            </div>
            <style>{`
                .services {
                    ${margin && `margin: ${margin} !important`};
                }
            `}</style>
        </>
    );
}
