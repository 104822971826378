import React from "react";
import {useTranslation} from "react-i18next";
import {MotionDiv} from "../../../custom components/MotionDiv";
import {SECTION} from "../../../../main/constants/SECTION";
import {getGlobalStyle} from "../../../../main/utility/utility";

function AboutUs({ backgroundColor, margin  }) {

    const { t } = useTranslation()
    const defaultBackgroundColor = getGlobalStyle("--tertiary-color")
    const defaultMargin = `0 auto ${getGlobalStyle("--padding-content")} auto`

    return (
        <>
            <div id={SECTION.aboutUs.id} className={"about-us"}>
                <div className={"about-us-container"}>
                    <h2 className="about-us-title">{t("about-us")}</h2>

                    <MotionDiv id={SECTION.aboutUs.id}
                               className={"about-us-description"}
                               content={
                                   <p>
                                       {t("about-us-description")}
                                   </p>
                               }
                    />
                </div>
            </div>
            <style>{`
            .about-us {
                background-color: ${backgroundColor || defaultBackgroundColor};
            }
            
             .about-us-container {
                margin: ${margin || defaultMargin} !important;
            }
            `}</style>
        </>
    );
}

export default AboutUs;
