function ConfirmAttendanceSection({ id }) {

    function submitAction(e) {
        e.preventDefault()
    }

    return (
        <section id={id}>
            <h1 className={"fill-out-title"}>Please confirm your attendance to the Event</h1>

            <div className={"invitation-form"}>
                <div className="checkbox-container">
                    <div className={"checkbox-input-container"}>
                        <input type="radio" name="single-select" className="round-checkbox"/>
                        <label htmlFor="checkbox1">Of course I’ll be there!</label>
                    </div>

                    <div className={"checkbox-input-container"}>
                        <input type="radio" name="single-select" className="round-checkbox"/>
                        <label htmlFor="checkbox2">Unfortunately, I won’t be able to come!</label>
                    </div>
                </div>

                <form className={"form-content"} onClick={submitAction}>
                    <input type="text"
                           placeholder={"Name and last name"}
                           className={"invitation-form-input"}/>
                    <input type="text"
                           placeholder={"Number of guests"}
                           className={"invitation-form-input"}/>

                    <button type="submit" className={"dark-button"}>Send</button>
                </form>
            </div>

            <p className={"confirm-attendance-title"}>Waiting for You!</p>
        </section>
    )
}

export default ConfirmAttendanceSection