import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import HowToOrder from "../home/how-to-order-section/HowToOrder";
import {getGlobalStyle} from "../../../main/utility/utility";

function HowToOrderPage() {
    return (
        <>
            <Header/>
            <HowToOrder backgroundColor={getGlobalStyle("--white")} margin={"50px 0"}/>
            <Footer/>
        </>
    )
}

export default HowToOrderPage