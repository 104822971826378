import {SECTION} from "../../../main/constants/SECTION";

export const SidebarData = [
    {
        key: SECTION.aboutUs.id,
        title: SECTION.aboutUs.title,
        urlName: SECTION.aboutUs.urlName
    },
    {
        key: SECTION.services.id,
        title: SECTION.services.title,
        urlName: SECTION.services.urlName
    },
    {
        key: SECTION.howToOrder.id,
        title: SECTION.howToOrder.title,
        urlName: SECTION.howToOrder.urlName
    },
    {
        key: SECTION.contactUs.id,
        title: SECTION.contactUs.title,
        urlName: ""
    }
];