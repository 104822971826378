import MapWithPin from "./MapWithPin";

function VenueLocation({ title, address, imageSrc, latitude, longitude }) {
    return (
        <section id="venue-location-section">
            <div className="venue-location-place-container">
                <div>
                    <p className="venue-location-place-title">{title}</p>
                    <div className="venue-location-image-container">
                        <img src={imageSrc} alt={title} />
                    </div>
                </div>
                <div>
                    <p className="venue-location-name">{address}</p>
                    <MapWithPin latitude={latitude} longitude={longitude} />
                </div>
            </div>
        </section>
    );
}

export default VenueLocation