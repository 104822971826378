import CountdownTimer from "../../../../custom components/countdown_timer/CountdownTimer";
import {SECTIONS} from "../helper/Helper";

function MainSection({ date }) {
    return (
        <section id={SECTIONS.mainSection}>
            <div className="main-section-container">
                <div className="main-section-content">
                    <div className="main-section-info-container">
                        <p className="main-section-info-title">
                            Սիրով հրավիրում ենք ձեզ մեր հարսանյաց արարողությանը
                        </p>
                        <h2 className="main-section-info-date">10 Դեկտեմբերի 2024 թ․</h2>
                        <p className="main-section-info-additional">Հարգանքով՝</p>
                        <h2 className="main-section-info-name">Հովհաննես և Մանե</h2>
                        <p className="main-section-info-additional">Մնացել է</p>
                    </div>
                    <div className="main-section-info-timer-container">
                        <CountdownTimer eventDate={new Date(date).getTime()} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MainSection