import VenueLocation from "./VenueLocation";
import {INVITATION_IMAGES} from "../../helper/Helper";

function VenueSections() {

    return (
        <div className={"venue-location-sections"}>
            <VenueLocation
                title="Սուրբ Սարգիս եկեղեցի"
                address="Հասցեն՝ ք․ Երևան, Իսրայելյան 21"
                imageSrc={INVITATION_IMAGES.churchImage}
                latitude={40.210027}
                longitude={44.462007}
            />

            <VenueLocation
                title="«Աղաբաբյանս» ռեստորանային համալիր"
                address="Հասցեն՝ ք․ Երևան, Նազարբեկյան 25/5"
                imageSrc={INVITATION_IMAGES.venueImage}
                latitude={40.177318}
                longitude={44.501587}
            />
        </div>
    )

}

export default VenueSections