import React, {useEffect, useState} from 'react';
import {MotionDiv} from "../../../custom components/MotionDiv";
import CustomSlider, {ResponsiveSettings} from "../../../custom components/CustomSlider";
import {SERVICE_TYPES} from "../../../../main/constants/SERVICE_TYPES";
import {motion} from "framer-motion";
import {v4 as uuid} from "uuid";
import {Link} from "react-router-dom";
import {getRouterPrefix} from "../../../router/getRouterPrefix";
import {useTranslation} from "react-i18next";
import {BestSellersData} from "../../../../api/jsons/BestSellersData";
import {pxToNumber, getGlobalStyle} from "../../../../main/utility/utility";
import {SECTION} from "../../../../main/constants/SECTION";

export default function BestSellers() {

    const {t} = useTranslation()
    const tabletMSize = getGlobalStyle("--tabletM")
    const desktopMSize = getGlobalStyle("--desktopM")
    const desktopLSize = getGlobalStyle("--desktopL")
    const sliderContainerPadding = getGlobalStyle("--slider-container-padding")
    const slideImageWidth = getGlobalStyle("--slide-image-rectangular-width")
    const sliderItemsGap = getGlobalStyle("--slider-items-gap")

    const [sliderPaddingSize, setSliderPaddingSize] = useState(0)

    useEffect(() => {
        const paddings = pxToNumber(sliderContainerPadding) * 2
        const imageSize = pxToNumber(slideImageWidth)
        const sliderItemsGapSize = pxToNumber(sliderItemsGap)

        setSliderPaddingSize((window.innerWidth - paddings - imageSize) / 2 - sliderItemsGapSize)
    }, []);

    return (
        <div id={SECTION.bestSellers.id} className={"best-sellers"}>
            <h2 className="best-sellers-title">{t("best-sellers")}</h2>
            <MotionDiv id={SECTION.bestSellers.id}
                       className={"best-sellers-slider-container"}
                       duration={2}
                       content={
                <CustomSlider itemsCount={BestSellersData.length}
                              slidesToShowCount={5}
                              responsive={[
                                  new ResponsiveSettings(
                                      Number(tabletMSize),
                                      1,
                                      true,
                                      `${sliderPaddingSize}px`
                                  ),
                                  new ResponsiveSettings(
                                      Number(desktopMSize),
                                      3
                                  ),
                                  new ResponsiveSettings(
                                      Number(desktopLSize),
                                      4
                                  )
                              ]}
                              content={BestSellersData.map(item => {
                    const category = SERVICE_TYPES.getItemByValue(item.type)

                    return (
                        <motion.div key={uuid()}
                                    className={"best-sellers-container"}
                                    whileHover={{scale: 0.95, transition: {duration: 0.3}}}>
                            <Link to={getRouterPrefix() + `/${category.title}`}>
                                <img src={category.image}/>
                            </Link>
                        </motion.div>
                    )
                })}/>
            }>
            </MotionDiv>
        </div>
)
    ;
}
