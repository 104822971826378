import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import {pxToNumber, getGlobalStyle} from "../../../../main/utility/utility";
import {SERVICE_TYPES} from "../../../../main/constants/SERVICE_TYPES";
import {v4 as uuid} from "uuid";
import { motion } from "framer-motion";
import '../styles/catalog-filters.css'

function CatalogFilters() {

    const {t} = useTranslation()
    const ref = useRef(null);
    const filtersFixedSize = getGlobalStyle("--filters-fixed-size")
    const filters = SERVICE_TYPES.allCases().map(el => el.title)
    const prices = [
        { from: "10000", to: "20000" },
        { from: "20000", to: "30000" },
        { from: "30000", to: "40000" },
        { from: "40000", to: null }
    ]

    const [isFixed, setIsFixed] = useState(false);
    const [topDistance, setTopDistance] = useState(pxToNumber(filtersFixedSize));
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [lastScrollY]);

    function scrollHandler() {
        fixViewPositionIfNeeded()
        moveFixedViewVertically()
    };

    function fixViewPositionIfNeeded() {
        if (ref.current) {
            const elementTop = ref.current.getBoundingClientRect().top;

            if (elementTop <= pxToNumber(filtersFixedSize)) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        }
    }

    function moveFixedViewVertically() {
        const currentScrollY = window.scrollY;

        if (currentScrollY > lastScrollY) {
            setTopDistance(pxToNumber(filtersFixedSize))
        } else {
            setTopDistance("150px")
        }

        setLastScrollY(currentScrollY);
    }

    return (
        <>
            <div className={"catalog-filters"} ref={ref}>
                <motion.div className={"catalog-filters-container"}
                            animate={{top: topDistance}}
                            transition={{ duration: 0.1 }}>
                    <div className={"filter-item"}>
                        <p className={"filter-item-name"}>{t("event")}</p>
                        <div className={"filter-button-container"}>{filters.map(filter => (
                            <button key={uuid()} className={"filter-button"}>{t(filter)}</button>
                        ))}</div>
                    </div>

                    <div className={"filter-item"}>
                        <p className={"filter-item-name"}>{t("price")}</p>
                        <div className={"filter-button-container"}>{prices.map(price => (
                            <button key={uuid()} className={"filter-button"}>
                                <span>{price.from}</span>
                                <span style={{padding: `0 ${price.to ? "8px" : "2px"}`}}>{price.to ? "-" : ""}</span>
                                <span>{price.to ? price.to : "+"}</span>
                            </button>
                        ))}</div>
                    </div>
                </motion.div>
            </div>
            <style>{`
            .catalog-filters-container {
                position: ${isFixed ? "fixed" : "unset"};
                top: ${topDistance}px;
            }
        `}</style>
        </>
    )
}

export default CatalogFilters