import React from 'react';
import { useParams } from 'react-router-dom';
import Invitation1 from "./invitation-1/Invitation1";
import Invitation2 from "./invitation-2/Invitation2";

function InvitationDashboard() {
    const { id } = useParams();

    return (
        buildInvitation()
    );

    function buildInvitation() {
        if (id === "1") {
            return <Invitation1/>
        } else if (id === "2") {
            return <Invitation2/>
        }
    }
}

export default InvitationDashboard;