import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import {useEffect, useRef, useState} from "react";
import {FetchedProductsData} from "../../../api/jsons/FetchedProductsData";
import {pxToNumber, getGlobalStyle} from "../../../main/utility/utility";
import CatalogHeader from "./components/CatalogHeader";
import CatalogFilters from "./components/CatalogFilters";
import CatalogListing from "./components/CatalogListing";
import './styles/catalog.css';
import {useScreenSize} from "../../../main/providers/ScreenSizeProvider";
import {SCREEN_SIZES} from "../../../main/constants/SCREEN_SIZES";
import {BASE_IMAGES} from "../../../main/constants/BASE_IMAGES";
import FloatingButtons from "../../custom components/floating-buttons/FloatingButtons";

function CatalogPage() {

    const footerRef = useRef()
    const headerHeight = getGlobalStyle("--header-height")
    const itemsCount = 230
    const { isSmallerThan } = useScreenSize();

    const [headerAndFooterHeight, setHeaderAndFooterHeight] = useState(0)

    useEffect(() => {
        scrollToTop()
    }, []);

    useEffect(() => {
        const footerHeight = footerRef.current.clientHeight

        setHeaderAndFooterHeight(pxToNumber(headerHeight) + footerHeight)
    }, [])

    function scrollToTop() {
        window.scrollTo({top: 0})
    }

    return (
        <>
            <div>
                <Header/>
                <div className={"catalog"}>
                    <CatalogHeader itemsCount={itemsCount}/>

                    <div className={"catalog-container"}>
                        {!isSmallerThan(SCREEN_SIZES.TABLET_M) && (
                            <CatalogFilters/>
                        )}
                        <CatalogListing invitationCards={FetchedProductsData}/>
                    </div>
                </div>

                <div ref={footerRef}>
                    <Footer/>
                </div>
            </div>
            <style>{`
            .catalog-listing {
                min-height: calc(100vh - ${headerAndFooterHeight}px)
            }
            `}</style>
        </>
    )
}

export default CatalogPage