import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { BASE_IMAGES } from "../../main/constants/BASE_IMAGES";

export class ResponsiveSettings {
    constructor(breakpoint, slidesToShow, centerMode = false, centerPadding) {
        this.breakpoint = breakpoint;
        this.settings = {
            slidesToShow,
            centerMode,
            centerPadding
        };
    }
}

function CustomPrevArrow({ className, onClick, currentSlide }) {
    return currentSlide === 0 ? null : (
        <div className={className} onClick={onClick}>
            <img src={BASE_IMAGES.rightArrowIcon} className={"arrow-icon"} alt="Prev" />
        </div>
    );
}

function CustomNextArrow({ className, onClick, currentSlide, slidesCount }) {
    return currentSlide === slidesCount - 1 ? null : (
        <div className={className} onClick={onClick}>
            <img src={BASE_IMAGES.rightArrowIcon} className={"arrow-icon"} alt="Next" />
        </div>
    );
}

export default function CustomSlider(
    {
        itemsCount,
        slidesToShowCount,
        responsive,
        content,
        centerMode = false,
        centerPadding = null,
    }
) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slidesToShow, setSlidesToShow] = useState(slidesToShowCount);

    const responsiveSettings = responsive?.map((res) => ({
        breakpoint: res.breakpoint,
        settings: res.settings,
    }));

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: slidesToShowCount,
        slidesToScroll: 1,
        centerMode: centerMode,
        centerPadding: `${centerPadding}px`,
        prevArrow: <CustomPrevArrow currentSlide={currentSlide} />,
        nextArrow: (
            <CustomNextArrow
                currentSlide={currentSlide}
                slidesCount={itemsCount - slidesToShow + 1}
            />
        ),
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
        responsive: responsiveSettings,
    };

    const updateSlidesToShow = () => {
        const windowWidth = window.innerWidth;
        const matchedBreakpoint = responsive.find(
            (res) => windowWidth <= res.breakpoint
        );

        if (matchedBreakpoint) {
            setSlidesToShow(matchedBreakpoint.settings.slidesToShow);
        } else {
            setSlidesToShow(slidesToShowCount);
        }
    };

    useEffect(() => {
        updateSlidesToShow();
        window.addEventListener("resize", updateSlidesToShow);

        return () => window.removeEventListener("resize", updateSlidesToShow);
    }, [responsive]);

    return <Slider {...sliderSettings}>{content}</Slider>;
}
