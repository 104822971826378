import {PageChecker} from "./pageChecker";

export function getGlobalStyle(variableName) {
  return typeof window === "undefined" ? "" : window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(variableName)
    .trim();
}

export function observeIntersection(closure = () => {}, threshold = 0.5) {
  return new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          closure()
        }
      },
      { threshold: threshold }
  )
}

export function scrollToSection(sectionName) {
    const element = document.getElementById(sectionName);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: "center" });
    }
}

export function isMatchingRoute(routeName) {
    const pageChecker = PageChecker()
    return pageChecker.isMatchingRoute(routeName)
};

export function pxToNumber(pxString) {
    if (typeof pxString === 'string' && pxString.endsWith('px')) {
        return parseFloat(pxString.replace('px', '')); // Remove 'px' and convert to number
    }

    return Number(pxString);
}