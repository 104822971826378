import {INVITATION_IMAGES} from "../../helper/Helper";
import {SCREEN_SIZES} from "../../../../../../main/constants/SCREEN_SIZES";
import {useScreenSize} from "../../../../../../main/providers/ScreenSizeProvider";
import ProgramOfDayMobile from "./ProgramOfDayMobile";
import ProgramOfDayDesktop from "./ProgramOfDayDesktop";

function ProgramOfDay() {

    const { isSmallerThan } = useScreenSize();

    return (
        <section id="program-of-day-section">
            <h1 className="program-of-day-title">Օրվա ծրագիր</h1>

            {isSmallerThan(SCREEN_SIZES.TABLET_M) ? (
                <ProgramOfDayMobile/>
            ) : (
                <ProgramOfDayDesktop/>
            )}
        </section>
    );
}

export default ProgramOfDay