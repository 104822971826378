import {useEffect, useState} from "react";
import './style/countdown-timer.css';

function CountdownTimer({ eventDate }) {

    const remainingTime = eventDate - new Date().getTime()

    const [timeRemaining, setTimeRemaining] = useState(remainingTime > 0 ? remainingTime : 0);

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date().getTime();
            const remainingTime = eventDate - now

            setTimeRemaining(remainingTime > 0 ? remainingTime : 0);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [eventDate]);

    return (
        <div className="countdown-container">
            <div className="time-units">

                <div className="time-unit">
                    <span className="time-value">{days}</span>
                    <span className="time-label">Days</span>
                </div>

                <div className="divider"/>
                <div className="time-unit">
                    <span className="time-value">{hours}</span>
                    <span className="time-label">Hours</span>
                </div>

                <div className="divider"/>
                <div className="time-unit">
                    <span className="time-value">{minutes}</span>
                    <span className="time-label">Minutes</span>
                </div>

                <div className="divider"/>
                <div className="time-unit">
                    <span className="time-value">{seconds}</span>
                    <span className="time-label">Seconds</span>
                </div>

            </div>
        </div>
    );
};

export default CountdownTimer